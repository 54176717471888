<template>
    <div>
        <div>{{ data.answer }}</div>
        <div class="generList">
            <div class="generItem" v-for="(gener,index) in data.sources" :key="gener.jobId" @dblclick="handleAssociation(gener,index+1)">
                <div class="generItem-top">
                    <div class="generItem-title">{{gener.systemName}}</div>
                    <div class="generItem-logo"></div>
                </div>
                <div class="generItem-content">
                    <ul class="case-ul">
                        <li>
                            <div class="row-title">所处时段：</div>
                            <div class="row-txt">
                                <el-tooltip v-if="gener.timeFrame && gener.timeFrame.length>36" class="item" effect="dark" :content="gener.timeFrame" placement="top-start">
                                    <el-button>{{gener.timeFrame}}</el-button>
                                </el-tooltip>
                                <span if-else>{{gener.timeFrame}}</span>
                            </div>
                        </li>
                        <li>
                            <div class="row-title">操作内容：</div>
                            <div class="row-txt">
                            <el-tooltip v-if="gener.jobContent && gener.jobContent.length>16" class="item" effect="dark" :content="gener.jobContent" placement="top-start">
                                <el-button>{{gener.jobContent}}</el-button>
                            </el-tooltip>
                            <span if-else>{{gener.jobContent}}</span>
                            </div>
                        </li>
                        <li>
                            <div class="row-title">故障判据：</div>
                            <div class="row-txt">
                            <el-tooltip class="item" effect="dark" placement="top-start" v-if="gener.criterions && gener.criterions.length>0">
                                <div slot="content">
                                    <div v-for="criter in gener.criterions" :key="criter.criterionId">{{criter.failureCriterion}}</div>
                                </div>
                                <el-button>{{gener.criterionName}}</el-button>
                            </el-tooltip>
                            </div>
                        </li>
                        <li>
                            <div class="row-title">可能原因：</div>
                            <div class="row-txt">
                            <el-tooltip class="item" effect="dark" placement="top-start" v-if="gener.criterions && gener.criterions.length>0">
                                <div slot="content">
                                <div v-for="criter in gener.criterions" :key="criter.criterionId">
                                    <span v-for="cause in criter.causes" :key="cause.causeId">{{cause.possibleCause}} </span>
                                </div>
                                </div>
                                <el-button class="clamp2">{{gener.causeName}}</el-button>
                            </el-tooltip>
                            <span v-else v-for="criter in gener.criterions" :key="criter.criterionId">
                                <span v-for="cause in criter.causes" :key="cause.causeId">{{cause.possibleCause}} </span>
                            </span>
                            </div>
                        </li>
                        <li>
                            <div class="row-title">解决方案：</div>
                            <div class="row-txt">
                            <el-tooltip class="item" effect="dark" placement="top-start" v-if="gener.criterions && gener.criterions.length>0">
                                <div slot="content">
                                <div v-for="criter in gener.criterions" :key="criter.criterionId">
                                    <div v-for="cause in criter.causes" :key="cause.causeId">
                                    <span v-for="solut in cause.solutions" :key="solut.solutionId">{{solut.preventionSolution}}</span>
                                    </div>
                                </div>
                                </div>
                                <el-button class="clamp3">{{gener.solutionName}}</el-button>
                            </el-tooltip>
                            <span v-else v-for="criter in gener.criterions" :key="criter.criterionId">
                                <span v-for="cause in criter.causes" :key="cause.causeId">
                                <span v-for="solut in cause.solutions" :key="solut.solutionId">{{solut.preventionSolution}}</span>
                                </span>
                            </span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>                                    
        </div>
        <div class="generTip" v-if="shaowTip">双击上面的预案可以查看与其关联的预案</div>
        <div class="generBox" v-if="cardData.length>0">
            <div class="generImg"><img :src="ImgLine" /></div>
            <div class="generData">
                <div class="generItem" v-for="item in cardData" :key="item.jobId">
                    <div class="generItem-top">
                        <div class="generItem-title">{{item.systemName}}</div>
                    </div>
                    <div class="generItem-content">
                        <ul class="case-ul">
                            <li>
                                <div class="row-title">所处时段：</div>
                                <div class="row-txt">
                                    <el-tooltip v-if="item.timeFrame && item.timeFrame.length>36" class="item" effect="dark" :content="item.timeFrame" placement="top-start">
                                        <el-button>{{item.timeFrame}}</el-button>
                                    </el-tooltip>
                                    <span if-else>{{item.timeFrame}}</span>
                                </div>
                            </li>
                            <li>
                                <div class="row-title">操作内容：</div>
                                <div class="row-txt">
                                <el-tooltip v-if="item.jobContent && item.jobContent.length>16" class="item" effect="dark" :content="item.jobContent" placement="top-start">
                                    <el-button>{{item.jobContent}}</el-button>
                                </el-tooltip>
                                <span if-else>{{item.jobContent}}</span>
                                </div>
                            </li>
                            <li>
                                <div class="row-title">故障判据：</div>
                                <div class="row-txt">
                                <el-tooltip class="item" effect="dark" placement="top-start" v-if="item.criterions && item.criterions.length>0">
                                    <div slot="content">
                                        <div v-for="criter in item.criterions" :key="criter.criterionId">{{criter.failureCriterion}}</div>
                                    </div>
                                    <el-button>{{item.criterionName}}</el-button>
                                </el-tooltip>
                                </div>
                            </li>
                            <li>
                                <div class="row-title">可能原因：</div>
                                <div class="row-txt">
                                <el-tooltip class="item" effect="dark" placement="top-start" v-if="item.criterions && item.criterions.length>0">
                                    <div slot="content">
                                    <div v-for="criter in item.criterions" :key="criter.criterionId">
                                        <span v-for="cause in criter.causes" :key="cause.causeId">{{cause.possibleCause}} </span>
                                    </div>
                                    </div>
                                    <el-button class="clamp2">{{item.causeName}}</el-button>
                                </el-tooltip>
                                <span v-else v-for="criter in item.criterions" :key="criter.criterionId">
                                    <span v-for="cause in criter.causes" :key="cause.causeId">{{cause.possibleCause}} </span>
                                </span>
                                </div>
                            </li>
                            <li>
                                <div class="row-title">解决方案：</div>
                                <div class="row-txt">
                                <el-tooltip class="item" effect="dark" placement="top-start" v-if="item.criterions && item.criterions.length>0">
                                    <div slot="content">
                                    <div v-for="criter in item.criterions" :key="criter.criterionId">
                                        <div v-for="cause in criter.causes" :key="cause.causeId">
                                        <span v-for="solut in cause.solutions" :key="solut.solutionId">{{solut.preventionSolution}}</span>
                                        </div>
                                    </div>
                                    </div>
                                    <el-button class="clamp3">{{item.solutionName}}</el-button>
                                </el-tooltip>
                                <span v-else v-for="criter in item.criterions" :key="criter.criterionId">
                                    <span v-for="cause in criter.causes" :key="cause.causeId">
                                    <span v-for="solut in cause.solutions" :key="solut.solutionId">{{solut.preventionSolution}}</span>
                                    </span>
                                </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>                                    
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name:"generChat",
    props: {
      data: {type: Object, required: true}, //数据
      cardData: {type: Array}
    },
    data(){
      return {
          shaowTip: true,
          ImgLine: ''
      }
    },
    methods: {
        handleAssociation(data,index) {
            this.shaowTip = false
            this.ImgLine = require('../../assets/image/line'+index+'.png')
            this.$parent.handleAssociation(index);
        }
    }
  } 
</script>