import request from '@/utils/request'
import { getToken } from "@/utils/auth";


/**
 * 智能专家反演
 * @param pram
 */
export const inversionChat = (data) => {
    return request({
      url: '/chat/main',
      method: 'post',
      data
    })
}

/**
 * 智能专家反演流式
 * @param pram
 */
export const inversionChatStream = async (data) => {
  console.log(process.env.VUE_APP_STREAM_API+"/jon/hello")
  return await fetch(process.env.VUE_APP_STREAM_API+"/jon/hello", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "xtoken": getToken()
    },
    body: JSON.stringify(data),
  });
}

/**
 * 结束对话
 * @param pram
 */
export const closeChat = (data) => {
  return request({
    url: '/chat/close',
    method: 'post',
    data
  })
}

/**
 * 故障树查询
 * @param pram
 */
export const troubleTree = (data) => {
  return request({
    url: '/debug/tree',
    method: 'post',
    data
  })
}

/**
 * 故障树查询
 * @param pram
 */
export const knowledgeList = (data) => {
  return request({
    url: '/api/knowledge/list',
    method: 'post',
    data
  })
}

/**
 * 故障问题列表查询
 * @param pram
 */
export const knowledgeFault = (data) => {
  return request({
    url: '/debug/call-word',
    method: 'get',
    params: data
  })
}