<template>
    <div>
        <pre style="white-space: pre-wrap;word-wrap: break-word;">{{ data.answer.answer }}</pre>
        <!-- <div>{{ data.answer.answer }}</div> -->
        <div class="inversionList" v-if="data.answer.sources.length>0">
            <div class="inversionItem" v-for="(invers,index) in data.answer.sources" :class="invers.isActive?'active':''" :key="index+1" @click="handleInvers(invers)">
                <div class="inversionItem-top">
                    <div class="inversionItem-title">{{invers.title}}</div>
                </div>
                <div class="inversionItem-content">{{invers.pageContent}}</div>
            </div>                                    
        </div>
    </div>
</template>

<script>
  export default {
    name:"ReplyChat",
    props: {
      data: {type: Object, required: true}, //数据
    },
    data(){
      return {

      }
    },
    methods: {
        handleInvers(data) {
            this.$parent.openDocument(data)
        }
    }
  } 
</script>