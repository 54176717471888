<template>
  <div class="chat_main" id="chat_main" v-if="this.rereply == 0 && answer.answer.answer">
    <!--反演--start-->
    <div class="msg-box-l">
      <div class="msg-r-logo">
        <!-- <img src="@/assets/image/chart_logo.png" alt=""> -->
      </div>
      <div class="msg-r">
        <div class="msg-r-content">
          <div class="msg-r-text">
            <trouble-tree
              :data="answer"
              v-if="appId == 'PGZS' && answer.chatId"
            />
            <reply-chat
              :data="answer"
              v-if="appId == 'ZNZJ' && answer.chatId"
            />
            <gener-chat
              :data="answer"
              :cardData="cardData"
              v-if="appId == 'LCFZ'"
            />
          </div>
        </div>
        <div class="msg-r-box">
          <div class="msg-r-box-l">
            <span class="msg-regeneration" @click="regener">重新生成</span>
            <span class="msg-conversation" @click="closeChat">结束对话</span>
          </div>

          <div class="msg-r-box-r">
            <div
              class="like-to"
              :class="likeTo ? 'active' : ''"
              @click="likeTo = !likeTo"
            ></div>
            <div
              class="step-on"
              :class="stepOn ? 'active' : ''"
              @click="stepOn = !stepOn"
            ></div>
            <div class="evaluate">
              <el-popover
                placement="bottom-end"
                title=""
                width="360"
                trigger="manual"
                content=""
                v-model="visible"
              >
                <div class="rate">
                  <div style="padding-right: 10px">请您打分</div>
                  <el-rate v-model="evaValue"></el-rate>
                </div>
                <textarea
                  class="eva-textarea"
                  placeholder="请输入您的评价"
                ></textarea>
                <div class="evaluate-bottom">
                  <el-button
                    type="default"
                    size="small"
                    @click="visible = false"
                    >取消</el-button
                  >
                  <el-button
                    type="primary"
                    size="small"
                    style="background: #c90e17 !important; border-color: #c90e17 !important;"
                    @click="visible = false"
                    >确定</el-button
                  >
                </div>
                <el-button
                  slot="reference"
                  class="evaluate-to"
                  :class="visible ? 'active' : ''"
                  @click="visible = !visible"
                ></el-button>
              </el-popover>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--反演--end-->
  </div>
</template>

<script>
import { inversionChat, closeChat, inversionChatStream } from "@/api/inversion";
import { planAssociation } from "@/api/process";
import TroubleTree from "../../components/trouble/zmtree.vue";
import ReplyChat from "../../components/main/replyChat.vue";
import GenerChat from "../../components/process/generChat.vue";
export default {
  name: "robot",
  components: {
    TroubleTree,
    ReplyChat,
    GenerChat,
  },
  data() {
    return {
      appId: this.$route.query.appId,
      question: this.$route.query.question,
      sessionId: null,
      procesId: this.$route.query.procesId,
      page: this.$route.query.page,
      size: this.$route.query.size,
      visible: false, //评价框开关
      evaValue: -1, //评分
      likeTo: false, //赞
      stepOn: false, //踩
      rereply: 0, //是否重新生成
      replyText: "",
      activeName: "first",
      isReplay: false,
      answer: { answer: { answer: "" }, chatId: "" },
      cardData: [],
      cardAllData: [],
      chatId: null,
      cache: this.$route.query.cache,
    };
  },
  created() {
    let sessionId = this.$route.query.sessionId;
    if (sessionId) {
      this.sessionId = JSON.parse(sessionId);
    } else {
      this.sessionId = null;
    }
    this.queryChatData();
  },
  methods: {
    queryChatData() {
      if (this.procesId) {
        this.queryPlanData();
      } else {
        this.queryInversion();
      }
    },
    async setAiText(resAi) {
      const reader = resAi.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let value_decode = "";
      setTimeout(() => {
        window.parent.changeLoading(false);
      }, 300);

      // eslint-disable-next-line no-constant-condition
      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          break;
        }
        value_decode += decoder.decode(value, { stream: true });
        const lines = value_decode.split("data: ");
        value_decode = lines.pop();

        for (const line of lines) {
          const data = line.substring(0, line.length - 2);
          this.answer.answer.answer += data;
        }
      }
    },
    async queryInversion() {
      //查询聊天信息
      if (this.cache) {
        this.answer = JSON.parse(localStorage.getItem("troubleData"));
        window.parent.changeLoading(false);
      } else {
        let data = {
          appId: this.appId,
          sessionId: this.sessionId,
          rereply: this.rereply,
          question: this.question,
        };
        window.parent.changeLoading(true);
        inversionChat(data)
          .then(async (res) => {
            console.log("res", res.chatId);
            this.rereply = 0;
            if (res && res.chatId) {
              if (res.answer.sources.length > 0) {
                res.answer.sources.map((item, index) => {
                  item.isActive = false;
                  item.id = index + 1000001;
                });
              }

              this.answer.chatId = res.chatId;

              this.answer = {
                chatId: res.chatId,
                answer: {
                  answer: "",
                  sources: [],
                },
                sessionId: res.sessionId,
              };

              const answerRes = await inversionChatStream({
                query: this.question,
              });
              // const reader = answerRes.body.getReader();
              // const decoder = new TextDecoder("utf-8");
              // const { value } = await reader.read();
              // const text = decoder.decode(value, { stream: true });
              // try {
              //   const json = JSON.parse(text)
              //   if(json.code == 401){
              //     this.$router.push('/login');
              //     return;
              //   }
              // } catch (error) {
              //   console.log(error)
              // }
              await this.setAiText(answerRes);
              this.answer.answer.sources = res.answer.sources;
            }
          })
          .catch(() => {
            window.parent.changeLoading(false);
          });
      }
    },
    queryPlanData() {
      let params = {
        proces_id: this.procesId,
        page: this.page,
        size: this.size,
      };
      window.parent.changeLoading(true);
      planAssociation(params)
        .then((res) => {
          if (res && res.datas) {
            res.datas.map((x) => {
              if (x.criterions && x.criterions.length > 0) {
                x.criterionName = "";
                x.causeName = "";
                x.solutionName = "";
                x.criterions.map((y) => {
                  x.criterionName += y.failureCriterion;
                  if (y.causes && y.causes.length > 0) {
                    y.causes.map((v) => {
                      x.causeName += v.possibleCause;
                      if (v.solutions && v.solutions.length > 0) {
                        v.solutions.map((u) => {
                          x.solutionName += u.preventionSolution;
                        });
                      }
                    });
                  }
                });
              }
            });
            this.answer.chatId = "chat" + this.procesId;
            this.answer.answer =
              "根据已知信息 “" + this.question + "” 分析如下：";
            this.answer.sources = res.datas.slice(0, 3);
            this.cardAllData = res.datas.slice(3, 12);
          }
          window.parent.changeLoading(false);
        })
        .catch(() => {
          window.parent.changeLoading(false);
        });
    },
    regener() {
      //重新生成
      if (this.procesId) {
        this.answer = { answer: "", sources: [] };
        this.cardData = [];
        this.queryPlanData();
      } else {
        if (!this.answer.sessionId) {
          return;
        }
        this.answer = { answer: { answer: "" } };
        this.rereply = 1;
        this.queryInversion();
      }
    },
    closeChat() {
      //关闭聊天
      if (this.procesId) {
        window.parent.closeChat();
      } else {
        if (!this.answer.sessionId) {
          return;
        }
        closeChat({ sessionId: this.answer.sessionId })
          .then((res) => {
            window.parent.closeChat();
          })
          .catch((err) => {});
      }
    },
    openDocument(data) {
      this.answer.answer.sources.map((item) => {
        item.isActive = false;
        if (item.id == data.id) {
          item.isActive = true;
        }
      });
      window.parent.handleInvers(data);
    },
    handleTree(data) {
      window.parent.handleClickTree(data);
    },
    handleAssociation(index) {
      let cardData = [];
      if (index === 1) {
        cardData = this.cardAllData.slice(0, 3);
      } else if (index === 2) {
        cardData = this.cardAllData.slice(3, 6);
      } else if (index === 3) {
        cardData = this.cardAllData.slice(6, 9);
      }
      cardData.map((x) => {
        if (x.criterions && x.criterions.length > 0) {
          x.criterionName = "";
          x.causeName = "";
          x.solutionName = "";
          x.criterions.map((y) => {
            x.criterionName += y.failureCriterion;
            if (y.causes && y.causes.length > 0) {
              y.causes.map((v) => {
                x.causeName += v.possibleCause;
                if (v.solutions && v.solutions.length > 0) {
                  v.solutions.map((u) => {
                    x.solutionName += u.preventionSolution;
                  });
                }
              });
            }
          });
        }
      });

      this.cardData = cardData;
    },
  },
};
</script>

<style scoped>
.chat_main {
  min-height: 300px;
}
</style>