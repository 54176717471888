import request from '@/utils/request'

/**
 * 获取型号列表
 * @param pram
 */
export const modelList = (data) => {
    return request({
      url: '/process/model/list',
      method: 'get',
      params: data
    })
}

/**
 * 根据型号获取细则列表
 * @param pram
 */
export const stepList = (data) => {
    return request({
      url: '/process/step/list',
      method: 'get',
      params: data
    })
}

/**
 * 根据id获取细则详情
 * @param pram
 */
export const stepDetail = (data) => {
  return request({
    url: '/process/step/detail',
    method: 'get',
    params: data
  })
}

/**
 * 真实预案关联
 * @param pram
 */
export const planAssociation = (data) => {
  return request({
    url: '/process/plan/list',
    method: 'get',
    params: data
  })
}

/**
 * 预案置顶
 * @param pram
 */
export const planTop = (data) => {
  return request({
    url: '/process/rel/proces-plan',
    method: 'post',
    data
  })
}

/**
 * 取消预案置顶
 * @param pram
 */
export const canclePlanTop = (data) => {
  return request({
    url: '/process/del-rel/proces-plan',
    method: 'post',
    data
  })
}