<template>
  <div>
    {{ data.answer.answer }}
    <div class="trouble-box" v-if="data.answer.sources.length>0">
        <div class="trouble-item" v-for="(trouble,sidx) in data.answer.sources.slice(0,3)" :key="sidx" @click="onNodeClick($event,trouble, 2)">
            <div class="trouble-title">{{trouble.fileName}}</div>
            <div class="trouble-similar">相似度:<span>{{ Math.round((1-trouble.score)*100) }}%</span></div>
        </div>
    </div>
    <div class="brain-tree-box">
      <div v-if="treeData!=null">
        <div class="tree-arrow" :class="treeSpread?'open':'close'" @click="spreadTree"></div>
        <div class="brain-tree-txt">故障归因如下：</div>
        <zm-tree-org  :style="'min-width:1100px; height: ' + treeHeight +'px;'"
          ref="tree"
          :data="treeData"
          :disabled="false"
          :horizontal="horizontal"      
          :collapsable="collapsable"
          :label-style="style"
          :node-draggable="false"
          :default-expand-level="10"
          :toolBar="toolBar"
          :scalable="false"
          :draggable="false"
          @on-contextmenu="onMenus"
          @on-node-click="onNodeClick"
        >
        
        <!-- 
          :only-one-node="onlyOneNode"
          :clone-node-drag="cloneNodeDrag"
          :before-drag-end="beforeDragEnd"
          :filterNodeMethod="filterNodeMethod"
          @on-node-drag="nodeDragMove"
          @on-node-drag-end="nodeDragEnd"
          @on-expand="onExpand"
          @on-node-dblclick="onNodeDblclick"
          @on-node-copy="onNodeCopy" -->
        </zm-tree-org>
      </div>
      <div v-if="treeShow==true && treeData==null" class="tree-none">
        根据已知问题，无法生成故障树
      </div>
      <ul v-if="treeShow==true" class="treeTips">
        <li :class="tip.active?'active':''" v-for="(tip,index) in tipData" :key="index" @click="handleLawsData(index)"></li>
      </ul>
    </div>
  </div>
</template>
<script>
  import { troubleTree, knowledgeList } from '@/api/inversion'
  export default {
    name:"ZmTree",
    props: {
      data: {type: Object, required: true}, //数据
    },
    data(){
      return {
        toolBar: {scale: false, restore: false, expand: false, zoom: false, fullscreen: false},
        keyword: '',
        treeData: null,
        horizontal: true,
        collapsable: false,
        onlyOneNode: true,
        cloneNodeDrag: true,
        expandAll: true,
        disaled: false,
        style: {
          background:'#fff',
          color:'#5e6d82'
        },
        treeShow: false,
        treeHeight: 60,
        treeId: 1,
        treeSpread: false,
        treeInfo: null,
        tipData: [{
          active:true
        },{
          active: false
        }],
        docData: {},
        cacheData: null,
        use_model: true,
      } 
    },
    created(){
      this.queryTroubleTree()
    }, 
    methods:{
      convertTreeData(tree, level, pid){
        tree["id"] = this.treeId++
        if(pid!=null){
          tree["pid"] = pid
        }

        if(level==1){
          tree["style"] = { color:'#fff', background:'#ff3f3c'}
        }else if(level==2){
          tree["style"] = { color:'#fff', background:'#f39800'}
        }

        if(typeof tree.children=="undefined" || tree.children.length==0){
          tree["style"] = null
        }

        if(tree.children && tree.children.length>0){
          if(level==3 && typeof tree.docs=="undefined"){
            tree.children = []
          }
        }

        if(tree.children && tree.children.length>0){
          for(let i=0; i<tree.children.length; i++){
            this.convertTreeData(tree.children[i], level+1, tree.id)
          }
        }else{
          this.queryDoc(tree)
          this.treeHeight += 38
        }
      },
      queryTroubleTree() {//查询故障树信息
          this.treeSpread=false
          if(this.$parent.cache) {
            this.treeData = JSON.parse(localStorage.getItem("treeData"));
            if(this.treeData) {
                this.treeId = 1
                this.convertTreeData(this.treeData, 1, null)
                this.treeShow = true
            }
          } else {
            this.rereply = 0
            window.parent.changeLoading(true);

            troubleTree({keyword: this.$parent.question,chatId:this.data.chatId,use_model: this.use_model}).then(res => {
                window.parent.changeLoading(false);
                if(res && res.tree) {
                  this.treeId = 1
                  this.cacheData = JSON.parse(JSON.stringify(res.tree))
                  this.treeHeight = 60
                  this.convertTreeData(res.tree, 1, null)
                  this.treeData = res.tree
                  this.docData = res
                  this.treeShow=true
                }else{
                  this.treeId = 1
                  this.cacheData = null
                  this.treeHeight = 60
                  this.treeData = null
                  this.docData = null
                  this.treeShow=true
                }
            }).catch(() => {window.parent.changeLoading(false);})
          }
      },
      queryDoc(tree){
        knowledgeList({keyword: tree["label"],chatId: tree.chatId}).then(res => {
              if(this.docData.doc_name!=null && this.docData.doc_name!=""){
                  let obj = {
                  content: this.docData.content,
                  fileName:this.docData.doc_name,
                  fileUrl: this.docData.file_url,
                  score: this.docData.last_score,
                  title: this.docData.doc_name.split("_")
                }
                if(res && res.sources) {
                  let sources = [obj]
                  for(let s=0; s<res.sources.length; s++){
                    if(this.docData.doc_name!=res.sources[s]["fileName"]){
                      sources.push(res.sources[s])
                      if(sources.length==3){
                        break
                      }
                    }
                  }
                  res.sources = sources
                }
              }
              if(res && res.sources) {
                let docs = null
                for(let s=0; s<res.sources.length; s++){
                  let doc = '';
                  if(s<3) {
                    doc = (s+1)+")"+ res.sources[s]["fileName"].split(".")[0]
                  }
                  if(doc.length>8){
                    doc = doc.substr(0, 8) + "..."
                  }
                  if(docs==null){
                    docs = doc
                  }else{
                    docs += "   " + doc
                  }
                }
                if(docs!=null){
                  tree.children = [{
                    id: this.treeId++,
                    pid: tree["id"],
                    label: docs,
                    className: "docs",
                    disabled: true,
                    style: {cursor: "default"},
                    docs:res.sources
                  }]
                }
              }
          }).catch(() => {})
      },
      spreadTree(){
        if(this.treeSpread==false){
          this.treeSpread=true
          this.spreadTreeDetail(this.treeData, null)
        }else{
          this.treeSpread=false
          this.packTreeDetail(this.treeData, null)
        }
      },
      spreadTreeDetail(tree, ptree){
        if(ptree!=null && tree.docs && tree.docs.length>0){
          let pchildren = []
          for(let d=0; d<tree.docs.length; d++){
            let doc = tree.docs[d].fileName.split(".")[0]
            if(doc.length>11){
              doc = doc.substr(0, 11) + "..."
            }
            doc += "  " + "【相似度：" + Math.round((1-tree.docs[d].score)*100) + "%"+"】"
            pchildren.push({
                id: this.treeId++,
                pid: ptree["id"],
                label: doc,
                className: "docs",
                disabled: true,
                style: {},
                info:tree.docs[d]
              })
          }
          this.treeHeight += 38*(tree.docs.length-1)
          ptree.children = pchildren
        }
        if(tree.children && tree.children.length>0){
          for(let i=0; i<tree.children.length; i++){
            this.spreadTreeDetail(tree.children[i], tree)
          }
        }
      },
      packTreeDetail(tree){
        if(tree.children && tree.children.length>0){
          if(tree.children[0].info){
            let docs = null
            let sources = []
            for(let s=0; s<tree.children.length; s++){
              sources.push(tree.children[s].info)
              let doc = (s+1)+")"+ tree.children[s].info.fileName.split(".")[0]
              if(doc.length>8){
                doc = doc.substr(0, 8) + "..."
              }
              if(docs==null){
                docs = doc
              }else{
                docs += "   " + doc
              }
            }
            if(docs!=null){
              tree.children = [{
                id: this.treeId++,
                pid: tree["id"],
                label: docs,
                className: "docs",
                disabled: true,
                style: {cursor: "default"},
                docs:sources
              }]
              this.treeHeight -= 38*(sources.length-1)
            }
          }else{
            for(let i=0; i<tree.children.length; i++){
              this.packTreeDetail(tree.children[i])
            }
          }
        }
      },
      // convertTreeDocs(tree, id){
      //   let subTree = null
      //   if(tree["id"]==id){
      //     return tree
      //   }
      //   if(tree.children && tree.children.length>0){
      //     for(let i=0; i<tree.children.length; i++){
      //       subTree = this.convertTreeDocs(tree.children[i], id)
      //       if(subTree!=null){
      //         return subTree
      //       }
      //     }
      //   }
      //   return subTree
      // },
      onMenus({node, command}) {
        if(command=='add'){
          this.treeHeight = 30
          this.treeId = 1
          this.convertTreeData(this.treeData, 1, null)
        }
      },
      onNodeClick(e, data, type) {
        if(data){
          this.treeInfo = []
          let troubleList = this.data.answer.sources
          let troubleObj = {id: 1000000, label: '相似案例',children: []}
          let idx = 0
          troubleList.forEach(item => {
            troubleObj.children.push({id:idx+1000001,pid: 1000000,fileName:item.fileName,fileUrl:item.fileUrl,pageContent:item.pageContent})
            idx ++
          });
          this.treeInfo.push(troubleObj)

          if(this.treeData && this.treeData.children.length>0) {
            this.spreadTreeDetail(this.treeData, null)
            this.getDocs(this.treeData)
          }
          if(data.children) {
            this.handleTree(data)
          } else if(data.info || type==2){
            let treeInfo = {}
            treeInfo = {"selectId": data.id, "tree": this.treeInfo}
            localStorage.setItem("troubleTree", JSON.stringify(treeInfo));
            localStorage.setItem("troubleData", JSON.stringify(this.data));
            localStorage.setItem("question",JSON.stringify(this.$parent.question));
            localStorage.setItem("treeData", JSON.stringify(this.cacheData));
            window.parent.location.href = '/trouble/list'
          }
        }
      },
      getDocs(tree){
        let docs = []
        if(tree.children && tree.children.length>0){
          if(tree.children[0].info){

            let tmpTree = {id:tree.id, label:tree.label, children:[]}
            for(let i=0; i<tree.children.length; i++){
              tmpTree.children.push({id: tree.children[i].id, pid: tree.children[i].pid, fileName: tree.children[i].info.fileName, 
                fileUrl: tree.children[i].info.fileUrl, pageContent: tree.children[i].info.pageContent})
            }
            this.treeInfo.push(tmpTree)
          }else{
            for(let i=0; i<tree.children.length; i++){
              this.getDocs(tree.children[i])
            }
          }
        }
        return docs
      },
      handleTree(data) {
        this.$parent.handleTree(data)
      },
      handleLawsData(index) {
        this.$parent.cache = false
        let tipData = this.tipData
        if(!tipData[index].active) {
          if(index === 0) {
            this.use_model = true
            this.queryTroubleTree()
          } else {
            this.use_model = false
            this.queryTroubleTree()
          }
        }
        tipData.forEach((x,idx) => {
          if(idx === index) {
            x.active = true
          } else {
            x.active = false
          }
        })
      }
      // filter(){
      //   this.$refs.tree.filter(this.keyword)
      // },
      // filterNodeMethod(value, data) {
      //   if (!value) return true;
      //   return data.label.indexOf(value) !== -1;
      // },
      //   onExpand(e, data) {
      //     console.log(e, data)
      //   },
      //   nodeDragMove(data){
      //     console.log(data)
      //   },
      //   beforeDragEnd(node, targetNode) {
      //     return new Promise((resolve, reject) => {
      //       if (!targetNode) reject();
      //       if (node.id === targetNode.id) {
      //         reject()
      //       } else {
      //         resolve()
      //       }
      //     })
      //   },
      //   nodeDragEnd(node, parentNode){
      //     node.id === parentNode.id && this.$Message.info("移动到自身")
      //   },
      //   onNodeClick(e, data) {
      //     this.$Message.info(data.label)
      //   },
      //   onNodeDblclick(){
      //     this.$Message.info("双击节点")
      //   },
      //   onNodeCopy(){
      //     this.$Message.success("复制成功")
      //   },
      //   handleNodeAdd(node){
      //     this.convertTreeData(this.treeData, 1)
      //     this.$Message.info("新增节点")
      //   },
      //   expandChange() {
      //     this.toggleExpand(this.data, this.expandAll);
      //   },
      //   toggleExpand(data, val) {
      //     if (Array.isArray(data)) {
      //       data.forEach(item => {
      //         this.$set(item, "expand", val);
      //         if (item.children) {
      //           this.toggleExpand(item.children, val);
      //         }
      //       });
      //     } else {
      //       this.$set(data, "expand", val);
      //       if (data.children) {
      //         this.toggleExpand(data.children, val);
      //       }
      //     }
      //   }
    }
  }
</script>
<style scoped>
/deep/ .tree-org-node__content .tree-org-node__text{
width:180px;
text-overflow:ellipsis;
overflow:hidden;
height: 28px;
font-size: 14px;
padding: 2px 5px;
/* color:#4b5867;
background:#fff; */
}
/deep/ .zm-tree-org {
    height: 100%;
    padding: 15px;
    position: relative;
    background-color: rgba(77,130,255, .1);
    border-radius: 20px;
}

/deep/ .horizontal .tree-org-node:not(:first-child):before {
    border-left: 1px solid #aabad1;
}
/deep/  .horizontal .tree-org-node:not(:last-child):after {
    border-left: 1px solid #aabad1;
}
/deep/ .horizontal .tree-org-node:not(:only-child):after{
  border-top: 1px solid #aabad1;
}
/deep/ .horizontal .tree-org-node__children:before{
  border-top: 1px solid #aabad1;
}
/deep/ .horizontal .tree-org-node:only-child:before{
  border-bottom: 1px solid #aabad1;
}
/deep/ .horizontal>.tree-org-node:only-child:before {
    border-bottom: 0;
}
/deep/ .horizontal .tree-org-node.collapsed {
    padding-top: 5px;
    padding-bottom: 5px;
}
/deep/ .horizontal .tree-org-node.is-leaf {
    padding-top: 5px;
    padding-bottom: 5px;
}
.trouble-box {
    padding: 10px 0;
    .trouble-item {
        display: flex;
        .trouble-title {
            padding: 0 10px 0 18px;
            background-image:url(../../assets/icon/icon_pdf.png);
            background-repeat: no-repeat;
            background-size: 14px 16px;
            background-position: 0 4px;
            color: #5f7098;
            cursor: pointer;
        }
    }
    
}


.trouble-similar {
        color: #999;
}
.trouble-similar span {
    padding-left: 5px;
    color: #32b16c;
}
/deep/ .tree-org-node__inner.docs {
    padding: 0 10px 0 12px;
    background-image:url(../../assets/icon/icon_pdf.png);
    background-repeat: no-repeat;
    background-size: 14px 16px;
    background-position: 0 6px;
    color: #5f7098;
    border: 0;
    text-align: left;
    width: 350px;
    -webkit-box-shadow: 0 0px 0px rgba(0,0,0,.15);
    box-shadow: 0 0px 0px rgba(0,0,0,.15);
    cursor: pointer;
}
/deep/ .tree-org-node__inner.docs .tree-org-node__text{
    width: 350px;
    text-decoration: underline #5f7098;
}

/**--arrow--**/
.brain-tree-box {
  position: relative;
}
.brain-tree-box .tree-arrow {
  position: absolute;
  top: 48px;
  right: 15px;
  z-index: 99;
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  cursor: pointer;
}
.brain-tree-box .tree-arrow.close {
  background-image: url(../../assets/icon/icon_close.png);
}
.brain-tree-box .tree-arrow.open {
  background-image: url(../../assets/icon/icon_open.png);
}
.mainContent .chat_main {
  position: relative;
}
.treeTips {
  padding: 0;
  margin: 0;
  list-style: none;
  width: 40px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: calc(50% - 40px);
  bottom: 15px;
}
.treeTips li {
  width: 12px;
  height: 12px;
  background-color: #666;
  border-radius: 50%;
  cursor: pointer;
}
.treeTips .active {
  background-color: #4d82ff;
  cursor: auto;
}
.tree-none{
  padding: 15px;
  position: relative;
  background-color: rgba(77,130,255, .1);
  border-radius: 20px;
  height: 70px;
}
</style>